import Immutable from 'seamless-immutable';

const initialState = Immutable({
  wellplate: {},
  wellplates: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WELLPLATE_SUCCESS':
      if (action.wellplate === undefined) return state;
      return state.setIn(['wellplate'], action.wellplate);

    case 'GET_ALL_WELLPLATES_SUCCESS':
    case 'GET_ALL_WELLPLATES_BY_PRINTER_SUCCESS':
      if (action.wellplates === undefined) return state;
      return state.setIn(['wellplates'], action.wellplates);

    default:
      return state;
  }
};
