import Immutable from 'seamless-immutable';

const defaultPrintParams = Immutable({
  extruder: 0,
  scale: 1,
  layerHeight: 0.2,
  speed: 6,
  xOffset: 0,
  yOffset: 0,
  zOffset: 0,
  infillType: 'None',
  infillDistance: 1,
  infillDirection: 0,
});

const initialState = Immutable({
  connectionStatus: 'disconnected',
  connectionHealth: 'disconnected',
  lastPrinterMessage: undefined,
  name: '',
  modelNumber: '',
  serialNumber: '',
  status: 'idle',
  activeSection: 'status',
  selectedExtruder: 0,
  extruderJogStep: 1,
  log: '',
  capabilities: [],

  deviceState: {},

  worklight: false,

  currentPrintIndex: 0,
  wellsToPrint: [],

  bed: {},

  extruders: [
    // {
    //   id: 0,
    //   connected: true,
    //   enabled: true,
    //   status: "down",
    //   position: [60, 30, 10],
    //   targetPosition: [60, 30, 10],
    //   tempActive: true,
    //   currentTemp: 35,
    //   targetTemp: 35,
    //   extruderActive: false,
    //   currentPressure: 30,
    //   targetPressure: 30,
    //   calibrated: true,
    // },
  ],

  // print: [{
  //   filename: '',
  //   fileId: '',
  //   fileType: '',
  //   checksum: '',
  //   preset: '',
  //   scale: 1,
  //   layerHeight: 0.2,
  //   speed: 10,
  //   travelSpeed: 20,
  //   xOffset: 0,
  //   yOffset: 0,
  //   zOffset: 0,
  // }],

  print: [],

  buildPlate: {
    type: '',
    wellCount: 0,
    manufacturer: '',
    model: '',
  },

  crosslinking: {
    duringPrint: {
      enabled: false,
      type: 0,
      intensity: 0,
      frequency: 1,
    },

    afterPrint: {
      enabled: false,
      type: 0,
      intensity: 0,
      duration: 0,
    },

    manual: {
      enabled: false,
      type: 0,
      intensity: 0,
    },

    advanced: {
      autoscroll: true,
      logContents: '',
    },
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_DEVICE':
      return initialState;

    case 'COMM_DISCONNECT':
      state = state.set('currentPrintIndex', 0);
      state = state.set('wellsToPrint', []);

      return state;

    case 'PRINTER_DISCONNECT':
      state = state.set('connectionStatus', 'disconnected');
      state = state.set('currentPrintIndex', 0);
      state = state.set('wellsToPrint', []);
      state = state.set('print', []);

      return state;

    case 'GET_PRINTER_STATE_SUCCESS':
      if (action.data === undefined) return state;

      return state.updateIn(['deviceState'], p => p.merge(action.data));

    case 'SET_PRINTER_DATA':
      if (action.printerData === undefined) return state;

      // Set extruder list if model number changed
      const modelNumber = parseInt(action.printerData.modelNumber, 10);
      if (!isNaN(modelNumber) && modelNumber !== state.modelNumber) {
        const extrudersList = Array(modelNumber)
          .fill()
          .map((e, i) => ({
            id: i,
            connected: false,
            enabled: false,
            calibrated: false,
            type: 'CORE',
            position: [undefined, undefined, undefined],
            targetPosition: [undefined, undefined, undefined],
            eAxisPosition: 'up',
            tempActive: false,
            tempStatus: 0, // 0=off, 1=cooling, 2=heating, 3=fans on
            currentTemp: undefined,
            targetTemp: undefined,
            currentPressure: undefined,
            targetPressure: undefined,
            extruderActive: false,
          }));

        state = state.setIn(['extruders'], extrudersList);
      }

      return state.merge(action.printerData);

    case 'SET_DEVICE_STATUS':
      if (action.status === undefined) return state;

      return state.setIn(['status'], action.status);

    case 'SET_ACTIVE_SECTION':
      if (action.section === undefined) return state;

      return state.setIn(['activeSection'], action.section);

    case 'SET_CONNECTION_STATUS':
      if (action.status === undefined) return state;

      state = state.setIn(['connectionStatus'], action.status);

      if (action.status === 'connected') {
        state = state.setIn(['connectionHealth'], 'good');
      }

      return state;

    case 'SET_CONNECTION_HEALTH':
      if (action.status === undefined) {
        return state;
      }

      return state.setIn(['connectionHealth'], action.status);

    case 'SET_WORKLIGHT_STATUS':
      if (action.status === undefined) return state;

      return state.setIn(['worklight'], action.status);

    // Handle socket message
    case 'PRINTER_MESSAGE': {
      if (action.state === undefined) return state;

      if (action.state.serialNumber !== state.serialNumber) return state;

      if (action.lastUpdate) state = state.setIn(['lastPrinterMessage'], Date.now());

      if (action.state.status) state = state.setIn(['status'], action.state.status.toLowerCase());

      if (action.state.Wellplate) {
        // TEMP
        // Change when firmware stores more wellplate info
        if (action.state.Wellplate.Type !== undefined) {
          let buildPlateType = 'Petri Dish';
          if (action.state.Wellplate.Type == 0) buildPlateType = 'Glass Slide';
          if (action.state.Wellplate.Type > 1) buildPlateType = `${action.state.Wellplate.Type}-Well Plate`;

          state = state.setIn(['buildPlate', 'type'], buildPlateType);
          state = state.setIn(['buildPlate', 'wellCount'], action.state.Wellplate.Type);
        }
      }

      if (action.state.crosslinking) {
        if (action.state.crosslinking.duringPrint) {
          state = state.setIn(['crosslinking', 'duringPrint'], action.state.crosslinking.duringPrint);
        }

        if (action.state.crosslinking.afterPrint) {
          state = state.setIn(['crosslinking', 'afterPrint'], action.state.crosslinking.afterPrint);
        }
      }

      if (action.state.bed) {
        state = state.setIn(['bed'], action.state.bed);
      }

      action.state.extruders.forEach((e, i) => {
        if (e.connected !== undefined) state = state.setIn(['extruders', i, 'connected'], e.connected);

        if (e.enabled !== undefined) state = state.setIn(['extruders', i, 'enabled'], e.enabled);

        if (e.calibrated !== undefined) state = state.setIn(['extruders', i, 'calibrated'], e.calibrated);

        if (e.status !== undefined) state = state.setIn(['extruders', i, 'status'], e.status);

        if (e.type !== undefined) {
          if (parseInt(e.type, 10) === 1000 || parseInt(e.type, 10) === 2000) {
            state = state.setIn(['extruders', i, 'type'], 'CORE');
          } else if (parseInt(e.type, 10) === 4990) {
            state = state.setIn(['extruders', i, 'type'], 'HT');
          }
        }

        if (e.Eposition !== undefined) {
          if (e.Eposition < 1) {
            state = state.setIn(['extruders', i, 'eAxisPosition'], 'down');
          } else if (e.Eposition >= 1) {
            state = state.setIn(['extruders', i, 'eAxisPosition'], 'up');
          }
        }

        if (e.position !== undefined) state = state.setIn(['extruders', i, 'position'], e.position);

        if (e.targetPosition !== undefined) state = state.setIn(['extruders', i, 'targetPosition'], e.targetPosition);

        if (e.tempActive !== undefined) state = state.setIn(['extruders', i, 'tempActive'], e.tempActive);

        if (e.tempStatus !== undefined) state = state.setIn(['extruders', i, 'tempStatus'], e.tempStatus);

        if (e.currentTemp !== undefined) state = state.setIn(['extruders', i, 'currentTemp'], e.currentTemp);

        if (e.targetTemp !== undefined) state = state.setIn(['extruders', i, 'targetTemp'], e.targetTemp);

        if (e.currentPressure !== undefined) state = state.setIn(['extruders', i, 'currentPressure'], e.currentPressure);

        if (e.targetPressure !== undefined) state = state.setIn(['extruders', i, 'targetPressure'], e.targetPressure);

        if (e.extruderActive !== undefined) state = state.setIn(['extruders', i, 'extruderActive'], e.extruderActive);
      });

      return state;
    }

    // Current print
    case 'SET_CURRENT_PRINT_INDEX':
      if (action.index === undefined) return state;

      return state.setIn(['currentPrintIndex'], action.index);

    case 'SET_WELLS_TO_PRINT':
      if (action.wells === undefined) return state;

      return state.setIn(['wellsToPrint'], action.wells);

    case 'SET_PRINT_PARAMS':
      if (action.params === undefined) return state;

      const file = action.params.file;
      const params = Immutable.without(action.params, 'file');

      if (!state.print[action.printIndex]) {
        state = state.setIn(['print', action.printIndex, 'file'], file);
        state = state.setIn(['print', action.printIndex, 'parameters'], defaultPrintParams);
      }

      return state.updateIn(['print', action.printIndex, 'parameters'], p => p.merge(params));

    case 'REMOVE_PRINT_PARAMS': {
      if (action.printIndex === undefined) return state;

      const newPrints = Immutable.asMutable(state.print);
      newPrints.splice(action.printIndex, 1);

      return state.setIn(['print'], newPrints);
    }

    // Build plate
    case 'SET_BUILD_PLATE':
      if (action.buildplate === undefined) return state;

      state = state.setIn(['buildPlate', 'type'], action.buildplate);
      state = state.setIn(['buildPlate', 'wellCount'], action.wellCount);

      return state;

    // Extruders
    case 'SET_SELECTED_EXTRUDER':
      if (action.selectedExtruder === undefined) return state;

      return state.setIn(['selectedExtruder'], action.selectedExtruder);

    case 'SET_EXTRUDER_JOG_STEP':
      if (action.step === undefined) return state;

      return state.setIn(['extruderJogStep'], action.step);

    case 'TOGGLE_EXTRUDER_ENABLED':
      if (action.extruder === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'enabled'], !state.extruders[action.extruder].enabled);

    case 'SET_EXTRUDER_STATUS':
      if (action.extruder === undefined || action.status === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'status'], action.status);

    case 'MOVE_EXTRUDER': {
      if (action.extruder === undefined || action.axis === undefined || action.delta === undefined) return state;

      let position = { ...state.extruders[action.extruder].targetPosition };
      let axisIndex = ['x', 'y', 'z'].indexOf(action.axis.toLowerCase());
      position[axisIndex] += action.delta;

      return state.setIn(['extruders', action.extruder, 'targetPosition'], position);
    }

    case 'SET_EXTRUDER_TARGET_POSITION': {
      if (action.extruder === undefined || action.position === undefined) return state;

      let { position } = action;
      if (!Array.isArray(position)) position = Object.values(position);

      return state.setIn(['extruders', action.extruder, 'targetPosition'], position);
    }

    case 'TOGGLE_EXTRUDER_TEMP_ACTIVE':
      if (action.extruder === undefined) return state;

      return state.setIn(
        ['extruders', action.extruder, 'tempActive'],
        action.forceSet !== undefined ? action.forceSet : !state.extruders[action.extruder].tempActive,
      );

    case 'SET_EXTRUDER_TARGET_TEMP':
      if (action.extruder === undefined || action.temp === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'targetTemp'], action.temp);

    case 'TOGGLE_EXTRUDER_PRESSURE_ACTIVE':
      if (action.extruder === undefined) return state;

      return state.setIn(
        ['extruders', action.extruder, 'pressureActive'],
        action.override !== undefined ? action.override : !state.extruders[action.extruder].pressureActive,
      );

    case 'SET_EXTRUDER_TARGET_PRESSURE':
      if (action.extruder === undefined || action.pressure === undefined) return state;

      return state.setIn(['extruders', action.extruder, 'targetPressure'], action.pressure);

    // Crosslinking
    case 'SET_CROSSLINKING_PARAMS':
      if (action.params === undefined) return state;

      return state.update('crosslinking', p => p.merge(action.params, { deep: true }));

    // Bed
    case 'TOGGLE_BED_TEMP_ACTIVE':
      return state.setIn(['bed', 'tempActive'], action.forceSet !== undefined ? action.forceSet : !state.bed.tempActive);

    case 'SET_BED_TARGET_TEMP':
      if (action.temp === undefined) return state;

      return state.setIn(['bed', 'targetTemp'], action.temp);

    default:
      return state;
  }
};
