import Immutable from 'seamless-immutable';

const initialState = Immutable({
  initialized: false,
  connecting: false,
  connected: false,
  printerConnected: false,
  error: false,
  manualSendStatus: 0,
  lastPrint: undefined,

  // Command buffering
  bufferCommands: false,
  commandBuffer: {},

  slicer: {
    status: 'idle',
    progress: 0,
    stlFile: '',
    gcodeFile: '',
    gcodeChecksum: '',
    queueFor: '',
    queuedForPrint: false,
    queuedForDownload: false,
  },

  tasks: {},

  devices: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'COMM_INITIALIZE':
      state = state.set('initialized', true);
      state = state.set('connecting', true);

      return state;

    case 'COMM_CONNECT':
      state = state.set('connecting', false);
      state = state.set('connected', true);

      return state;

    case 'COMM_DISCONNECT':
      state = state.set('connecting', false);
      state = state.set('connected', false);
      state = state.set('printerConnected', false);

      return state;

    case 'PRINTER_CONNECT':
      state = state.set('printerConnected', true);
      state = state.set('error', false);
      return state;

    case 'PRINTER_DISCONNECT':
      return state.set('printerConnected', false);

    case 'PRINTER_SEND_MESSAGE_SUCCESS':
      return state.set('error', false);

    case 'PRINTER_SEND_MESSAGE_ERROR':
      return state.set('error', true);

    case 'PRINTER_SET_MANUAL_SEND_STATUS':
      return state.set('manualSendStatus', action.status);

    case 'RESET_SLICER_STATUS':
      return state.setIn(['slicer'], {
        status: 'idle',
        progress: 0,
        stlFile: '',
        gcodeFile: '',
        gcodeChecksum: '',
        queueFor: '',
        queuedForPrint: false,
        queuedForDownload: false,
      });

    case 'SLICER_SUBMIT_WITH_PARAMS_SUCCESS':
      state = state.setIn(['slicer', 'progress'], 0);
      state = state.setIn(['slicer', 'queueFor'], action.queueFor);
      state = state.setIn(['slicer', 'gcodeFile'], '');
      state = state.setIn(['slicer', 'gcodeChecksum'], '');

      return state;

    case 'SLICER_SUBMIT_MULTIPLE_WITH_PARAMS':
      return state.setIn(['slicer', 'status'], 'slicing');

    case 'SLICER_SUBMIT_MULTIPLE_WITH_PARAMS_SUCCESS':
      state = state.setIn(['slicer', 'status'], 'slicing');
      state = state.setIn(['slicer', 'progress'], 0);
      state = state.setIn(['slicer', 'queueFor'], action.queueFor);
      state = state.setIn(['slicer', 'gcodeFile'], '');
      state = state.setIn(['slicer', 'gcodeChecksum'], '');

      return state;

    case 'SLICER_SUBMIT_MULTIPLE_WITH_PARAMS_FAILURE':
      state = state.setIn(['slicer', 'status'], 'error');
      state = state.setIn(['slicer', 'progress'], 0);
      state = state.setIn(['slicer', 'queueFor'], '');
      state = state.setIn(['slicer', 'queuedForPrint'], false);
      state = state.setIn(['slicer', 'queuedForDownload'], false);
      state = state.setIn(['slicer', 'gcodeFile'], '');
      state = state.setIn(['slicer', 'gcodeChecksum'], '');

      return state;

    case 'NOTIFICATIONS_SLICER_MESSAGE':
      if (!action.notification) return state;

      if (action.notification.type === 'ERROR') {
        state = state.setIn(['slicer', 'status'], 'error');
        state = state.setIn(['slicer', 'progress'], 0);
      } else if (action.notification.type === 'PROGRESS') {
        state = state.setIn(['slicer', 'progress'], action.notification.progress);
      } else if (action.notification.type === 'COMPLETE') {
        if (action.notification.error && action.notification.error.length > 0) {
          state = state.setIn(['slicer', 'status'], 'error');
          state = state.setIn(['slicer', 'progress'], 0);
        } else {
          state = state.setIn(['slicer', 'status'], 'idle');
          state = state.setIn(['slicer', 'progress'], 0);
          state = state.setIn(['slicer', 'gcodeFile'], action.notification.gcodeId);
        }

        if (state.slicer.queueFor === 'print') {
          state = state.setIn(['slicer', 'queueFor'], '');
          state = state.setIn(['slicer', 'queuedForPrint'], true);
          state = state.setIn(['slicer', 'queuedForDownload'], false);
        } else if (state.slicer.queueFor === 'download') {
          state = state.setIn(['slicer', 'queueFor'], '');
          state = state.setIn(['slicer', 'queuedForPrint'], false);
          state = state.setIn(['slicer', 'queuedForDownload'], true);
        }
      }

      return state;

    case 'TASK_MESSAGE':
      if (!action.taskId || !action.notification) return state;

      state = state.setIn(['tasks', action.taskId], action.notification);

      return state;

    case 'DOWNLOAD_FILE_SUCCESS':
      return state.setIn(['slicer', 'queuedForDownload'], false);

    case 'SET_LAST_PRINT':
      if (action.uuid === undefined) return state;

      return state.setIn(['lastPrint'], action.uuid);

    // Command buffer
    case 'PRINTER_START_BUFFERING_COMMANDS':
      if (action.printer === undefined) return state;

      state = state.setIn(['bufferCommands'], true);

      return state;

    case 'PRINTER_STOP_BUFFERING_COMMANDS':
      if (action.printer === undefined) return state;

      state = state.setIn(['bufferCommands'], false);

      return state;

    case 'PRINTER_APPEND_TO_COMMAND_BUFFER':
      if (action.printer === undefined || action.command === undefined) return state;

      state = state.updateIn(['commandBuffer', action.printer], (buffer, command) => (Array.isArray(buffer) ? (
        buffer.concat(command)
      ) : (
        ['C28', ...command]
      )), action.command);

      return state;

    case 'PRINTER_SEND_COMMAND_BUFFER':
      if (action.printer === undefined) return state;

      state = state.setIn(['commandBuffer', action.printer], ['C28']);

      return state;

    default:
      return state;
  }
};
