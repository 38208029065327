import Immutable from 'seamless-immutable';

const initialState = Immutable({
  status: 'idle',
  materials: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_MATERIALS':
      return state.set('status', 'fetching');

    case 'GET_ALL_MATERIALS_SUCCESS':
    case 'GET_ALL_MATERIALS_BY_PRINTER_SUCCESS':
      state = state.set('status', 'idle');
      state = state.set('materials', action.materials);

      return state;

    default:
      return state;
  }
};
