import Immutable from 'seamless-immutable';

const initialState = Immutable({
  status: 'idle',
  lastUpdate: undefined,
  searchString: '',
  sortBy: 'Status',
  printers: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRINTER_SORT':
      if (action.sortBy === undefined) return state;

      return state.setIn(['sortBy'], action.sortBy);

    case 'SET_PRINTER_SEARCH_STRING':
      if (action.searchString === undefined) return state;

      return state.setIn(['searchString'], action.searchString);

    case 'GET_ALL_PRINTERS':
      return state.setIn(['status'], 'fetching');

    case 'GET_ALL_PRINTERS_SUCCESS': {
      if (action.printers === undefined) return state;

      const printers = action.printers.map(p => ({ ...p, status: 'UNKNOWN' }));

      state = state.setIn(['status'], 'idle');
      state = state.setIn(['printers'], printers);

      return state;
    }

    case 'CHECK_PRINTER_STATUSES':
      return state.setIn(['status'], 'checking');

    case 'CHECK_PRINTER_STATUS':
      return state.setIn(['status'], 'checking');

    case 'CHECK_PRINTER_STATUS_SUCCESS': {
      state = state.setIn(['status'], 'idle');
      state = state.setIn(['lastUpdate'], Date.now());

      if (action.printerList === undefined) return state;

      const updatedPrinters = state.printers.map(printer => ({
        ...printer,
        status: action.printerList[printer.serialNumber] && action.printerList[printer.serialNumber].status ? (
          action.printerList[printer.serialNumber].status
        ) : (
          'UNKNOWN'
        ),
        lastState: action.printerList[printer.serialNumber] && action.printerList[printer.serialNumber].lastState ? (
          action.printerList[printer.serialNumber].lastState.toUpperCase()
        ) : (
          'UNKNOWN'
        ),
        deviceData: action.printerList[printer.serialNumber] && action.printerList[printer.serialNumber].deviceData ? (
          action.printerList[printer.serialNumber].deviceData
        ) : (
          printer.deviceData
        ),
      }));

      return state.merge({ printers: updatedPrinters });
    }

    case 'GENERATE_PRINTER_TOKEN_SUCCESS':
      if (action.token === undefined) return state;

      return state.setIn(['printerToken'], action.token);

    default:
      return state;
  }
};
