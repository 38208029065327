// import Immutable from 'seamless-immutable';
import produce from 'immer';

type ProjectState = {
  status: string,
  error: string,
  searchString: string,
  sortBy: 'Recently changed' | 'Newest' | 'Name',
  page: number,
  project: {
    name: string,
    printer: {
      modelNumber: number,
    },
    updatedAt: '',
  },
  projects: {}[],
  step: string
}

const initialState: ProjectState = {
  status: 'idle',
  error: '',
  searchString: '',
  sortBy: 'Recently changed',
  page: 0,
  project: {
    name: '',
    printer: {
      modelNumber: 1,
    },
    updatedAt: '',
  },
  projects: [],
  step: '-1',
};

export default produce((state = initialState, action) => {
  switch (action.type) {
    case 'RESET_PROJECT':
      state.project = initialState.project;
      return state;

    case 'SET_PROJECT':
      state.project = action.project;
      return state;

    case 'SET_PROJECT_STEP':
      state.step = action.step;
      return state;

    case 'SET_PROJECT_SORT':
      state.sortBy = action.sortBy;
      return state;

    case 'SET_PROJECT_SEARCH_STRING':
      state.searchString = action.searchString;
      return state;

    case 'SET_PROJECT_PAGE':
      state.page = action.page;
      return state;

    case 'READ_PROJECT':
      state.status = 'fetching';
      return state;

    case 'READ_PROJECT_SUCCESS':
      state.status = 'idle';
      state.project = action.project;
      return state;

    case 'UPDATE_PROJECT_SUCCESS':
      state.project = action.project;
      return state;

    case 'GET_ALL_PROJECTS':
      state.status = 'fetching';
      return state;

    case 'GET_ALL_PROJECTS_SUCCESS':
      state.status = 'idle';
      state.projects = action.projects;
      return state;

    case 'GET_PROJECT_TEMPLATES':
      state.status = 'fetching';
      return state;

    case 'GET_PROJECT_TEMPLATES_SUCCESS':
      state.status = 'idle';
      state.projects = action.projects;
      return state;

    case 'READ_PROJECT_FAILURE':
      state.error = 'Project failed to load. Ensure it was not deleted and try again';
      return state;

    default:
      return state;
  }
});
