import Color from 'color';

//
// Px to rem conversion
//
export function px(p) {
  return `${Math.round(p) / 16}rem`;
}

//
// Fade a color
//
export function fade(color, amount) {
  return Color(color)
    .fade(amount)
    .string();
}

//
// Lighten a color
//
export function lighten(color, amount) {
  return Color(color)
    .lighten(amount)
    .hex();
}

//
// Whiten a color
//
export function whiten(color, amount) {
  return Color(color)
    .whiten(amount)
    .hex();
}
