import React, { useEffect, lazy, Suspense } from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { StylesProvider, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from 'styled-components';

import { din } from './styles/variables';

import * as UserActions from './state/redux/actions/userActions';
import * as OrganizationActions from './state/redux/actions/organizationActions';

// Route-based code splitting
const ErrorPage = lazy(() => import(/* webpackChunkName: 'ErrorPage' */ './pages/Error'));
const NotFound = lazy(() => import(/* webpackChunkName: 'NotFoundPage' */ './pages/NotFound'));

const Account = lazy(() => import(/* webpackChunkName: 'Account' */ './pages/Account'));
const Login = lazy(() => import(/* webpackChunkName: 'LoginPage' */ './pages/Login'));
const Register = lazy(() => import(/* webpackChunkName: 'Register' */ './pages/Register'));
const ForgotPassword = lazy(() => import(/* webpackChunkName: 'ForgotPassword' */ './pages/ForgotPassword'));
const ResetPassword = lazy(() => import(/* webpackChunkName: 'ResetPassword' */ './pages/ResetPassword'));
const ActivatePlan = lazy(() => import(/* webpackChunkName: 'ActivatePlan' */ './pages/ActivatePlan'));

const Team = lazy(() => import(/* webpackChunkName: 'TeamPage' */ './pages/Team'));
const Teams = lazy(() => import(/* webpackChunkName: 'TeamsPage' */ './pages/Teams'));
const CreateTeam = lazy(() => import(/* webpackChunkName: 'CreateTeamPage' */ './pages/CreateTeam'));

const Printers = lazy(() => import(/* webpackChunkName: 'PrintersPage' */ './pages/Printers'));
const RegisterPrinter = lazy(() => import(/* webpackChunkName: 'Register' */ './pages/RegisterPrinter'));
const UnregisterPrinter = lazy(() => import(/* webpackChunkName: 'Unregister' */ './pages/UnregisterPrinter'));
const CreatePrinter = lazy(() => import(/* webpackChunkName: 'CreatePrinter' */ './pages/CreatePrinter'));
const GeneratePrinterToken = lazy(() => import(/* webpackChunkName: 'GeneratePrinterToken' */ './pages/GeneratePrinterToken'));

const CreateDevice = lazy(() => import(/* webpackChunkName: 'CreateDevice' */ './pages/CreateDevice'));
const RegisterDevice = lazy(() => import(/* webpackChunkName: 'RegisterDevice' */ './pages/RegisterDevice'));
const ManageDevice = lazy(() => import(/* webpackChunkName: 'ManageDevice' */ './pages/ManageDevice'));
const Devices = lazy(() => import(/* webpackChunkName: 'Devices' */ './pages/Devices'));

const Projects = lazy(() => import(/* webpackChunkName: 'Projects' */ './pages/Projects'));
const ProjectEditor = lazy(() => import(/* webpackChunkName: 'ProjectEditor' */ './pages/ProjectEditor'));
const ProjectPrint = lazy(() => import(/* webpackChunkName: 'ProjectPrint' */ './pages/ProjectPrint'));
const ExperimentTemplates = lazy(() => import(/* webpackChunkName: 'ExperimentTemplates' */ './pages/ExperimentTemplates'));


// Customize Material UI theme
const theme = createMuiTheme({
  shape: {
    borderRadius: 0,
  },

  typography: {
    fontFamily: din,
  },

  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application
    },
  },

  palette: {
    background: {
      default: '#FFFFFF',
    },
  },
});

// Private routes
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (sessionStorage.getItem('id_token') ? <Component {...props} /> : <Redirect to="/login" />)} />
);

// Error boundary
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Suspense fallback={<div />}>
          <ErrorPage />
        </Suspense>
      );
    }

    return this.props.children;
  }
}

const App = (props) => {
  const onPrem = (window.hostEnvironment && window.hostEnvironment === 'ON_PREM') || (process.env.REACT_APP_HOST_ENVIRONMENT === 'ON_PREM');

  useEffect(() => {
    if (sessionStorage.getItem('id_token')) {
      props.getUser()
        .then(() => {
          if (props.user?.authenticated) {
            props.getOrganization();
          }
        });
    }
  }, []);

  useEffect(() => {
    if (props.user.authenticated && props.organization.organizations?.length === 0) {
      props.getOrganization();
    }
  }, [props.user.authenticated]);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <Switch>
                <PrivateRoute path="/" exact component={Printers} />
                <PrivateRoute path={['/printers', '/printers/:serialNumber']} component={Printers} />
                <PrivateRoute path="/projects" component={Projects} />
                <PrivateRoute path="/experiments" component={Projects} />
                <PrivateRoute path={['/create-project', '/edit-project']} component={ProjectEditor} />
                <PrivateRoute path={['/create-experiment', '/edit-experiment']} component={ProjectEditor} />
                <PrivateRoute path={['/print-project', '/print-experiment']} component={ProjectPrint} />
                <PrivateRoute path={"/experiment-templates"} component={ExperimentTemplates} />
                <Route path="/login" component={Login} />
                <Route path="/create-account" component={Register} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/account" component={Account} />
                <PrivateRoute path="/activate" component={ActivatePlan} />
                <PrivateRoute path="/teams/:teamId" component={Team} />
                <PrivateRoute path="/teams" component={Teams} />
                <PrivateRoute path="/create-team" component={CreateTeam} />
                <PrivateRoute path="/register" component={RegisterPrinter} />
                <PrivateRoute path="/unregister" component={UnregisterPrinter} />

                {(!onPrem) ? (
                  <>
                    {/* Cloud specific routes */}
                    <PrivateRoute path="/create-printer" component={CreatePrinter} />
                    <PrivateRoute path="/devices" component={Devices} />
                    <PrivateRoute path="/create-device" component={CreateDevice} />
                    <PrivateRoute path="/register-device" component={RegisterDevice} />
                    <PrivateRoute path={['/generate-printer-token', '/generate-printer-token/:serialNumber']} component={GeneratePrinterToken} />
                    <Route path="/reset-password" component={ResetPassword} />
                  </>
                ) : (
                  <>
                    {/* On-prem specific routes */}
                    <PrivateRoute path="/manage-device" component={ManageDevice} />
                  </>
                )}

                {/* 404 Page */}
                <Route path="/404" component={NotFound} />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </StylesProvider>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    organization: state.organization,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions, ...OrganizationActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
