/* jslint browser: true */
/* global window */
/* global document */

import './util/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { setAuthToken, setApiUrl, setResourcesUrl } from '@allevi/allevi-api-wrapper';
import Cookie from 'js-cookie';


// import App from './App_MAINTENANCE'; // TEMP for maintenance
import App from './App'; // Normal routes
import * as serviceWorker from './serviceWorker';

import Store from './state/redux/store';

const StoreInstance = Store();
const rootEl = document.getElementById('root');

// console.log(`Allevi Bioprint build ${process.env.REACT_APP_COMMIT_SHA}`);

//  Set auth token from cookie, if exists
if (Cookie.get('allevi-session-token')) {
  const token = Cookie.get('allevi-session-token');
  sessionStorage.setItem('id_token', token);
  setAuthToken(token);
}

// // Build API URL depending on branch
const branchPrefix = process.env.REACT_APP_BRANCH_NAME;
let urlPrefix = '';
let urlSuffix = '';
if (!branchPrefix) urlPrefix = 'dev-';
if (!branchPrefix) urlSuffix = '-dev';
if ((branchPrefix === 'master') || (branchPrefix === 'staging') || (branchPrefix === 'main')) {
  urlPrefix = 'production-';
  urlSuffix = '-prod';
} else if (branchPrefix === 'development') {
  urlPrefix = 'dev-';
  urlSuffix = '-dev';
} else {
  urlPrefix = `${branchPrefix}-`;
  urlSuffix = `-${branchPrefix}`;
}

let apiUrl = `https://${urlPrefix}api.allevi.app`;
let resourcesUrl = `https://${urlPrefix}resources.allevi.app`;

if (process.env.REACT_APP_HOST_ENVIRONMENT === 'ON_PREM') {
  if (!window.couchDBConfig) {
    if (process.env.REACT_APP_CLIENT_USER && process.env.REACT_APP_CLIENT_SECRET) {
      const envvar = require('envvar');
      const clientUser = envvar.string('REACT_APP_CLIENT_USER', '');
      const clientSecret = envvar.string('REACT_APP_CLIENT_SECRET', '');
      const url = `https://${clientUser}:${clientSecret}@couchdb.allevi.software:6984`;

      window.couchDBConfig = { url };
    }
  }

  window.hostEnvironment = process.env.REACT_APP_HOST_ENVIRONMENT;

  // Set API URL for on-prem platform
  apiUrl = 'https://api.allevi.software:8000';
}

if (process.env.REACT_APP_API_URL) {
  apiUrl = process.env.REACT_APP_API_URL;
  resourcesUrl = process.env.REACT_APP_RESOURCES_URL;
}

if ((branchPrefix !== 'master') && (branchPrefix === 'staging')) {
  console.log(`API URL: ${apiUrl}`);
  console.log(`Resources URL: ${resourcesUrl}`);
}

setApiUrl(apiUrl);
setResourcesUrl(resourcesUrl);



ReactDOM.render(
  <Provider store={StoreInstance}>
    <App />
  </Provider>,
  rootEl,
);

if (process.env.REACT_APP_HOST_ENVIRONMENT === 'CLOUD') {
  // Intercom setup
  const APP_ID = 'ruwb5u0t';
  window.intercomSettings = {
    app_id: APP_ID,
    custom_launcher_selector: '#intercom_trigger',
    hide_default_launcher: true,
  };

  /* eslint-disable-next-line */
  // Pre-filled app ID in the widget URL: 'https://widget.intercom.io/widget/ruwb5u0t'
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ruwb5u0t';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
