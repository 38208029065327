import { fade } from '../util/style';
import { breakpoints } from './breakpoints';

export const siteName = (window.hostEnvironment && window.hostEnvironment === 'ON_PREM') ?
  'Allevi Bioprint On-Premises' : 'Allevi Bioprint Online';

// Allevi color palette
export const colors = {
  white: '#FFFFFF',
  alleviRed: '#E63231',
  alleviRedLight: '#FF6A5C',
  alleviRedFaded: '#FFCCD1',
  alleviRedPale: '#FCECEC',
  alleviRedDark: '#AC0009',
  emerald: '#00D098',
  emeraldLight: '#D0F6EC',
  emeraldDark: '#009E6A',
  rain: '#177E89',
  rainLight: '#54AEB9',
  indigo: '#084C61',
  indigoDark: '#002437',
  deliMustard: '#FFC857',
  deliMustardLight: '#FFE6B2',
  deliMustardDark: '#C89825',
  milk2: '#FCFBF9',
  gray7: '#EDEDEB',
  gray13: '#DFDEDC',
  gray45: '#8D8C8B',
  gray87: '#212121',
  errorClapton: '#9B0A00',
  trafficCone: '#ED9600',
};

export const modelColors = [
  colors.indigo,
  colors.deliMustard,
  colors.emerald,
  colors.alleviRed,
  colors.indigoDark,
  colors.deliMustardDark,
  colors.emeraldDark,
  colors.alleviRedDark,
];

export const groupColors = [
  colors.rainLight,
  colors.deliMustard,
  colors.alleviRed,
  colors.emerald,
  colors.indigo,
  colors.deliMustardDark,
  colors.emeraldDark,
  colors.alleviRedDark,
  '#FFFF00', '#1CE6FF', '#FF34FF', '#FF4A46', '#008941', '#006FA6', '#A30059',
  '#FFDBE5', '#7A4900', '#0000A6', '#63FFAC', '#B79762', '#004D43', '#8FB0FF', '#997D87',
  '#5A0007', '#809693', '#FEFFE6', '#1B4400', '#4FC601', '#3B5DFF', '#4A3B53', '#FF2F80',
  '#61615A', '#BA0900', '#6B7900', '#00C2A0', '#FFAA92', '#FF90C9', '#B903AA', '#D16100',
  '#DDEFFF', '#000035', '#7B4F4B', '#A1C299', '#300018', '#0AA6D8', '#013349', '#00846F',
  '#372101', '#FFB500', '#C2FFED', '#A079BF', '#CC0744', '#C0B9B2', '#C2FF99', '#001E09',
  '#00489C', '#6F0062', '#0CBD66', '#EEC3FF', '#456D75', '#B77B68', '#7A87A1', '#788D66',
  '#885578', '#FAD09F', '#FF8A9A', '#D157A0', '#BEC459', '#456648', '#0086ED', '#886F4C',
  '#34362D', '#B4A8BD', '#00A6AA', '#452C2C', '#636375', '#A3C8C9', '#FF913F', '#938A81',
  '#575329', '#00FECF', '#B05B6F', '#8CD0FF', '#3B9700', '#04F757', '#C8A1A1', '#1E6E00',
  '#7900D7', '#A77500', '#6367A9', '#A05837', '#6B002C', '#772600', '#D790FF', '#9B9700',
  '#549E79', '#FFF69F', '#201625', '#72418F', '#BC23FF', '#99ADC0', '#3A2465', '#922329',
  '#5B4534', '#FDE8DC', '#404E55', '#0089A3', '#CB7E98', '#A4E804', '#324E72', '#6A3A4C',
  '#83AB58', '#001C1E', '#D1F7CE', '#004B28', '#C8D0F6', '#A3A489', '#806C66', '#222800',
  '#BF5650', '#E83000', '#66796D', '#DA007C', '#FF1A59', '#8ADBB4', '#1E0200', '#5B4E51',
  '#C895C5', '#320033', '#FF6832', '#66E1D3', '#CFCDAC', '#D0AC94', '#7ED379', '#012C58',
  '#7A7BFF', '#D68E01', '#353339', '#78AFA1', '#FEB2C6', '#75797C', '#837393', '#943A4D',
  '#B5F4FF', '#D2DCD5', '#9556BD', '#6A714A', '#001325', '#02525F', '#0AA3F7', '#E98176',
  '#DBD5DD', '#5EBCD1', '#3D4F44', '#7E6405', '#02684E', '#962B75', '#8D8546', '#9695C5',
  '#E773CE', '#D86A78', '#3E89BE', '#CA834E', '#518A87', '#5B113C', '#55813B', '#E704C4',
  '#00005F', '#A97399', '#4B8160', '#59738A', '#FF5DA7', '#F7C9BF', '#643127', '#513A01',
  '#6B94AA', '#51A058', '#A45B02', '#1D1702', '#E20027', '#E7AB63', '#4C6001', '#9C6966',
  '#64547B', '#97979E', '#006A66', '#391406', '#F4D749', '#0045D2', '#006C31', '#DDB6D0',
  '#7C6571', '#9FB2A4', '#00D891', '#15A08A', '#BC65E9', '#FFFFFE', '#C6DC99', '#203B3C',
  '#671190', '#6B3A64', '#F5E1FF', '#FFA0F2', '#CCAA35', '#374527', '#8BB400', '#797868',
  '#C6005A', '#3B000A', '#C86240', '#29607C', '#402334', '#7D5A44', '#CCB87C', '#B88183',
  '#AA5199', '#B5D6C3', '#A38469', '#9F94F0', '#A74571', '#B894A6', '#71BB8C', '#00B433',
  '#789EC9', '#6D80BA', '#953F00', '#5EFF03', '#E4FFFC', '#1BE177', '#BCB1E5', '#76912F',
  '#003109', '#0060CD', '#D20096', '#895563', '#29201D', '#5B3213', '#A76F42', '#89412E',
  '#1A3A2A', '#494B5A', '#A88C85', '#F4ABAA', '#A3F3AB', '#00C6C8', '#EA8B66', '#958A9F',
  '#BDC9D2', '#9FA064', '#BE4700', '#658188', '#83A485', '#453C23', '#47675D', '#3A3F00',
  '#061203', '#DFFB71', '#868E7E', '#98D058', '#6C8F7D', '#D7BFC2', '#3C3E6E', '#D83D66',
  '#2F5D9B', '#6C5E46', '#D25B88', '#5B656C', '#00B57F', '#545C46', '#866097', '#365D25',
  '#252F99', '#00CCFF', '#674E60', '#FC009C', '#92896B', '#1E2324', '#DEC9B2', '#9D4948',
  '#85ABB4', '#342142', '#D09685', '#A4ACAC', '#00FFFF', '#AE9C86', '#742A33', '#0E72C5',
  '#AFD8EC', '#C064B9', '#91028C', '#FEEDBF', '#FFB789', '#9CB8E4', '#AFFFD1', '#2A364C',
  '#4F4A43', '#647095', '#34BBFF', '#807781', '#920003', '#B3A5A7', '#018615', '#F1FFC8',
  '#976F5C', '#FF3BC1', '#FF5F6B', '#077D84', '#F56D93', '#5771DA', '#4E1E2A', '#830055',
  '#02D346', '#BE452D', '#00905E', '#BE0028', '#6E96E3', '#007699', '#FEC96D', '#9C6A7D',
  '#3FA1B8', '#893DE3', '#79B4D6', '#7FD4D9', '#6751BB', '#B28D2D', '#E27A05', '#DD9CB8',
  '#AABC7A', '#980034', '#561A02', '#8F7F00', '#635000', '#CD7DAE', '#8A5E2D', '#FFB3E1',
  '#6B6466', '#C6D300', '#0100E2', '#88EC69', '#8FCCBE', '#21001C', '#511F4D', '#E3F6E3',
  '#FF8EB1', '#6B4F29', '#A37F46', '#6A5950', '#1F2A1A', '#04784D', '#101835', '#E6E0D0',
  '#FF74FE', '#00A45F', '#8F5DF8', '#4B0059', '#412F23', '#D8939E', '#DB9D72', '#604143',
  '#B5BACE', '#989EB7', '#D2C4DB', '#A587AF', '#77D796', '#7F8C94', '#FF9B03', '#555196',
  '#31DDAE', '#74B671', '#802647', '#2A373F', '#014A68', '#696628', '#4C7B6D', '#002C27',
  '#7A4522', '#3B5859', '#E5D381', '#FFF3FF', '#679FA0', '#261300', '#2C5742', '#9131AF',
  '#AF5D88', '#C7706A', '#61AB1F', '#8CF2D4', '#C5D9B8', '#9FFFFB', '#BF45CC', '#493941',
];

// Global style
export const globalStyle = {
  '@global': {
    'html, body': {
      [breakpoints.down('sm')]: {
        fontSize: '10px',
      },

      [breakpoints.up('sm')]: {
        fontSize: '10px',
      },

      [breakpoints.up('md')]: {
        fontSize: '12px',
      },

      [breakpoints.up('lg')]: {
        fontSize: '14px',
      },
    },

    hr: {
      width: '100%',
      border: 'none',
      borderBottom: `1px solid ${colors.gray13}`,
      margin: '24px 0',
    },
  },
};

// URW DIN font family
export const din = [
  'urw-din',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

// URW DIN semi-condensed font family
export const dinCondensed = [
  'urw-din-semi-condensed',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

// IBM Plex monospace font family
export const mono = ['ibm-plex-mono', 'monospace', 'sans-serif'].join(',');

// Shadow from MUI toggle switch
export function buttonShadow(color = '#000') {
  return `0px 1px 3px 0px ${fade(color, 0.8)}, 0px 1px 1px 0px ${fade(color, 0.86)}, 0px 2px 1px -1px ${fade(color, 0.88)}`;
}

// Ease transition from MUI
export const easeTransition = '0.15s cubic-bezier(0.4, 0, 0.2, 1)';

// Pulse animation
export const pulse = `
  @keyframes pulse {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

// Pulse animation without fully disappearing
export const pulsePartial = `
  @keyframes pulsePartial {
    0% {
      opacity: 1;
    }

    25% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
`;

// Pulse animation for notifications
export const pulseHalf = `
  @keyframes pulseHalf {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
`;
